import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@ion/env';
import pkg from '../../../package.json';

export function initDatadog() {
  datadogRum.init({
    applicationId: '8b972e68-601f-4e13-8c76-2bd995b186c9',
    clientToken: 'pub1c560e54f31dae51ada7c0ca1b4763ac',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'red-kite-cockpit',
    // environment the front end is hosted on
    env: environment.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogLogs.init({
    clientToken: 'pub1c560e54f31dae51ada7c0ca1b4763ac',
    site: 'datadoghq.eu',
    service: 'red-kite-cockpit',
    env: environment.environment,
    version: pkg.version,
    sessionSampleRate: 100,
    forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
  });
}
